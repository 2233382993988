@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .link {
    @apply text-primary-400 underline visited:text-purple-600;
  }
  /* SpreadJsのスタイルを適用 */
  @import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2013white";
}

/* NOTE: フローチャート内のantdのButtonのスタイルが適用されるようにbg-colorを上書きする */
.ant-btn-primary {
  background-color: #1677ff !important;
}

/* NOTE: Tiptapのスタイルを適用 */
.ProseMirror {
  padding: 40px;

  ul {
    padding: 0 1rem;
    list-style: disc;
  }

  ol {
    padding: 0 1rem;
    list-style: decimal;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  h1 {
    font-size: 2em;
  }

  h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1.17em;
  }

  h4 {
    font-size: 1em;
  }

  h5 {
    font-size: 0.83em;
  }

  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
  ul[data-type="taskList"] {
    list-style: none;
    padding: 0;

    p {
      margin: 0;
    }

    li {
      display: flex;

      > label {
        flex: 0 0 auto;
        margin-right: 0.5rem;
        user-select: none;
      }

      > div {
        flex: 1 1 auto;
      }
    }
  }
}

.tippy-box {
  table {
    border-collapse: collapse;
    margin: 0;
    overflow: hidden;
    table-layout: fixed;
    width: 100%;

    td,
    th {
      border: 2px solid #ced4da;
      box-sizing: border-box;
      min-width: 1em;
      padding: 3px 5px;
      position: relative;
      vertical-align: top;

      > * {
        margin-bottom: 0;
      }
    }

    th {
      background-color: #f1f3f5;
      font-weight: bold;
      text-align: left;
    }

    .selectedCell:after {
      background: rgba(200, 200, 255, 0.4);
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      pointer-events: none;
      position: absolute;
      z-index: 2;
    }

    .column-resize-handle {
      background-color: #adf;
      bottom: -2px;
      position: absolute;
      right: -2px;
      pointer-events: none;
      top: 0;
      width: 4px;
    }

    p {
      margin: 0;
    }
  }
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

.highlight {
  @apply !bg-primary-200;
}

/* SpreadJsのカスタムメニュー用のicon*/
.spreadjs-summary-icon {
  width: 1.2rem;
  height: 1.2rem;
  background-image: url("/SpreadJs/summary.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle; /* テキストとの垂直方向の位置を調整 */
}

.spreadjs-concretize-icon {
  width: 1.2rem;
  height: 1.2rem;
  background-image: url("/SpreadJs/concretize.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle; /* テキストとの垂直方向の位置を調整 */
}

.spreadjs-generate-icon {
  width: 1.2rem;
  height: 1.2rem;
  background-image: url("/SpreadJs/generate.svg");
  background-size: contain;
  background-repeat: no-repeat;
  display: inline-block;
  vertical-align: middle; /* テキストとの垂直方向の位置を調整 */
}
